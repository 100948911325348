import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class IconModule {
  constructor(
    @Optional() @SkipSelf() parentModule: IconModule,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    if (parentModule) {
      throw new Error(
        'IconModule is already registered. Import it in the AppModule only',
      );
    }

    this.registerIcon('close', 'close');
    this.registerIcon('google', 'google');
    this.registerIcon('facebook', 'facebook');
    this.registerIcon('google_1', 'google_custom');
    this.registerIcon('facebook_2', 'facebook_custom');
  }

  /**
   * Register icon to app
   * @param iconName name used in html selector prop
   * @param fileName file name in assets/icons without extension
   */
  registerIcon(iconName: string, fileName: string): void {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/icons/${fileName}.svg`,
      ),
    );
  }
}
