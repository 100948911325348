import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleMapsService } from 'services/google-maps.service';
import { LanguageService } from 'services/language.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  constructor(
    private readonly languageService: LanguageService,
    private readonly userService: UserService,
    private gMapsService: GoogleMapsService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    // add redirect to the new adoptme page
    window.location.href =
      'https://www.almonature.com/it/adoptme?utm_source=go.almonature.com&utm_medium=partner&utm_campaign=adoptme&utm_content=adoptme&utm_term=adoptme';
    // Set mat datepicker locale
    this.subscription.add(
      this.languageService.currentLang$.subscribe((lang) => {
        const htmlElement = document.querySelector('html');
        this.renderer.setAttribute(htmlElement, 'lang', lang.langCode);
      }),
    );

    this.gMapsService.insertScriptTag();
    this.subscription.add(this.userService.watchActiveUser().subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
