/**
 * Also manually add available locale's to transloco.config.js
 */

export interface Language {
  language: string;
  country: string;
  langCode: string;
  countryCode: string;
  locale: string;
}

export const availableLanguages: Language[] = [
  {
    language: 'Italian',
    country: 'Italy',
    langCode: 'it',
    countryCode: 'IT',
    locale: 'it-IT'
  },
  {
    language: 'Italian',
    country: 'Switzerland',
    langCode: 'it',
    countryCode: 'CH',
    locale: 'it-CH'
  },
  {
    language: 'French',
    country: 'France',
    langCode: 'fr',
    countryCode: 'FR',
    locale: 'fr-FR'
  },
  {
    language: 'French',
    country: 'Belgium',
    langCode: 'fr',
    countryCode: 'BE',
    locale: 'fr-BE'
  },
  {
    language: 'French',
    country: 'Switzerland',
    langCode: 'fr',
    countryCode: 'CH',
    locale: 'fr-CH'
  },
  {
    language: 'German',
    country: 'Germany',
    langCode: 'de',
    countryCode: 'DE',
    locale: 'de-DE'
  },
  {
    language: 'German',
    country: 'Switzerland',
    langCode: 'de',
    countryCode: 'CH',
    locale: 'de-CH'
  },
  {
    language: 'Dutch',
    country: 'Netherlands',
    langCode: 'nl',
    countryCode: 'NL',
    locale: 'nl-NL'
  },
  {
    language: 'Dutch',
    country: 'Belgium',
    langCode: 'nl',
    countryCode: 'BE',
    locale: 'nl-BE'
  },
  {
    language: 'English',
    country: 'United Kingdom',
    langCode: 'en',
    countryCode: 'GB',
    locale: 'en-GB'
  }
  // {
  //   language: 'English',
  //   country: 'United States',
  //   langCode: 'en',
  //   countryCode: 'US',
  //   locale: 'en-US',
  // },
  // {
  //   language: 'English',
  //   country: 'Canada',
  //   langCode: 'en',
  //   countryCode: 'CA',
  //   locale: 'en-CA',
  // },
  // {
  //   language: 'Finish',
  //   country: 'Finland',
  //   langCode: 'fi',
  //   countryCode: 'FI',
  //   locale: 'fi-FI',
  // },
  // {
  //   language: 'Spanish',
  //   country: 'Spain',
  //   langCode: 'es',
  //   countryCode: 'ES',
  //   locale: 'es-ES',
  // },
  // {
  //   language: 'Swedish',
  //   country: 'Sweden',
  //   langCode: 'sv',
  //   countryCode: 'SV',
  //   locale: 'sv-SV',
  // },
];

export const defaultLanguage: Language = availableLanguages.find(
  (lang) => lang.locale === 'it-IT'
);

export const defaultTranslocoLanguage: Language = availableLanguages.find(
  (lang) => lang.locale === 'en-GB'
);
