import { NgModule } from '@angular/core';
import { canActivate } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { defaultLanguage } from 'constants/languages.constant';
import { almoLocaleUrlMatcher } from 'helpers/url-matcher.helper';
import { redirectUnauthorizedToLogin } from './utils/guard-pipe.util';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: defaultLanguage.locale,
  },
  {
    matcher: almoLocaleUrlMatcher,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'account',
      },
      {
        path: 'sign-in-magic-link',
        loadChildren: () =>
          import(
            './pages/sign-in-with-magic-link/sign-in-with-magic-link.module'
          ).then((m) => m.SignInWithMagicLinkModule),
      },
      // {
      //   path: 'campaign',
      //   loadChildren: () =>
      //     import('./pages/campaign/campaign.module').then(
      //       (m) => m.CampaignModule,
      //     ),
      // },
      {
        path: 'auth',
        ...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
      },
    ],
  },
  {
    path: 'dashboard',
    redirectTo: `${defaultLanguage.locale}/auth/dashboard`,
  },
  {
    path: 'promotions',
    redirectTo: `${defaultLanguage.locale}/auth/promotions`,
  },
  {
    path: 'pets',
    redirectTo: `${defaultLanguage.locale}/auth/pets`,
  },
  {
    path: 'profile',
    redirectTo: `${defaultLanguage.locale}/auth/profile`,
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
