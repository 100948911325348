<section class="stepper">
  <div class="stepper__wrapper">
    <div class="stepper__header py-5">
      <div class="progress">
        <div class="progress-bar"
             [style.width.%]="progressBarWidth"></div>
      </div>
    </div>

    <div class="stepper__content">
      <ng-container [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>
    </div>

    <div class="stepper__buttons fadeIn py-20"
         *ngIf="!hideButtons">
      <div class="stepper__buttons__wrapper"
           *transloco="let t; read: 'buttons'">
        <div class="prev-button">
          <button *ngIf="selectedIndex !== 0"
                  mat-button
                  color="primary"
                  cdkStepperPrevious
                  (click)="clickedPrev()">
            <mat-icon class="mr-5">arrow_back</mat-icon> {{ t('prev') }}
          </button>
        </div>

        <div class="next-button">
          <div class="next-loading"
               *ngIf="showLoadingNextButton">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
          <button *ngIf="!showLoadingNextButton"
                  mat-raised-button
                  color="primary"
                  [disabled]="selected ? selected.stepControl?.invalid : false"
                  (click)="clickedNext()"
                  cdkStepperNext>
            {{ t('next') }} <mat-icon class="ml-5">arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
