export const getMimeTypeFromExtension = (type: string) => {
  if (mimeTypes[type]) {
    return mimeTypes[type];
  }
};

export const supportedMimeTypes = () => {
  const mimes = Object.values(mimeTypes);
  // remove duplicates and return
  return mimes.filter((mime, index) => mimes.indexOf(mime) === index);
};

export const supportedImageMimeTypes = () => {
  const mimes = supportedMimeTypes();
  return mimes.filter((mime) => mime.includes('image'));
};

const mimeTypes = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  gif: 'image/gif',
  pdf: 'application/pdf'
};
