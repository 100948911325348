import { Injectable } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import {
  supportedImageMimeTypes,
  supportedMimeTypes,
} from 'constants/mime-types.constant';
import { Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

export type FileUploadResponse = {
  uploadTask: AngularFireUploadTask;
};

export type FileUploadingResponse = {
  url: string;
  isLoading: false;
};

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  supportedMimes = supportedMimeTypes();
  supportedImageMimes = supportedImageMimeTypes();
  constructor(private readonly storage: AngularFireStorage) {}

  async fileUpload(file: File, path: string): Promise<FileUploadResponse> {
    if (this.supportedMimes.includes(file.type)) {
      const fullPath = this.generateUniquePath(path, file.name);

      const uploadTask = this.storage.ref(fullPath).put(file);

      return { uploadTask };
    }
  }

  watchFileUpload(
    { uploadTask }: FileUploadResponse,
    file: FormControl,
  ): Observable<FileUploadingResponse | number | string> {
    file.patchValue({ isLoading: true });
    return uploadTask.percentageChanges().pipe(
      tap(
        (val) =>
          typeof val === 'number' &&
          file.patchValue({ isLoading: true, percentage: Math.round(val) }),
      ),
      finalize(async () => {
        file.patchValue(await (await uploadTask).ref.getDownloadURL());
      }),
    );
  }

  /**
   * Replace multiple slashes into single slash
   */
  private normalizePath(path: string): string {
    return path.replace(/\/+/g, '/');
  }

  /**
   * Check if file name already exists in storage
   */
  private checkFileExists(path: string): Promise<any> {
    return this.storage.ref(path).getDownloadURL().pipe(take(1)).toPromise();
  }

  /**
   * Generate unique file name with the same extension as original file
   */
  generateUniquePath(path: string, fileName: string): string {
    const name = fileName.split('.')[0];
    const timestamp = new Date().getTime();
    const extension = fileName.split('.').pop();

    const fullPath = `${path}/${name}-${timestamp}.${extension}`;

    return this.normalizePath(fullPath);
  }
}
