/**
 * Normalize date from firestore timestamps or date string or date instance to new date instance (`Date`).
 * Normalizing date instance to avoid side effects.
 * @param date Firestore timestamps / Date string / Date instance
 */

import { dateStringRegexPattern } from 'constants/regexp.constant';

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function normalizeDate(date: any): Date | undefined {
  if (date?.toDate) {
    // Convert Firestore Timestamps to date instance
    return new Date(date.toDate());
  } else if (date instanceof Date) {
    // Just assign to new date instance to avoid side effects
    return new Date(date);
  } else if (typeof date === 'string' && Date.parse(date)) {
    // Convert date string to date instance
    return new Date(date);
  } else {
    return undefined;
  }
}

/**
 * @param Date
 * return DD-MMM-YYYY, ex: 17-Nov-2019
 */
export function getDateString(date: any): string {
  if (!date) {
    return null;
  }

  // Test if date is already in valid string format DD-MMM-YYYY
  if (typeof date === 'string' && dateStringRegexPattern.test(date)) {
    return date;
  }

  const newDate = new Date(date);

  const day = Math.abs(newDate.getDate());
  const month = getMonthString(newDate.getMonth());
  const year = Math.abs(newDate.getFullYear());
  const formattedDate = [day, month, year];

  return formattedDate.join('-');
}

export function getDateTimeString(date: any): string {
  if (!date) {
    return null;
  }

  // toTimeString() returns 17:34:51 GMT+0530 (India Standard Time)
  // remove text from braces
  const timeString = new Date(date)
    .toTimeString()
    .split(' ')
    .splice(0, 2)
    .join(' ');
  const dateString = getDateString(date);

  return `${dateString}, ${timeString}`;
}

export function getMonthString(month: number): string {
  return months[month];
}
