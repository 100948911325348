export interface IProduct {
  item_code: string;
  weight: string | any;
  description: string;
  ean_code: string;
  bar_code: string;
  animal: string;
  card_image_main: string;
  no_of_pieces?: string | number;
}

export interface IFreeItemSelection {
  freeItems: IProduct[];
  petAge: string;
  quantity: number;
}

export interface ICampaignProduct extends IFreeItemSelection {
  items: IProduct[];
}

export interface ISegmentConfig extends IFreeItemSelection {
  descriptions: string[];
}

export enum ProductConfig {
  Segment = 'SEGMENT',
  Item = 'ITEM',
}

export enum CampaignType {
  WetDry = 'WET_DRY',
  WetWet = 'WET_WET',
  DryDry = 'DRY_DRY',
  DryWet = 'DRY_WET',
}

export enum AnimalType {
  Cat = 'CAT',
  Dog = 'DOG',
}

export interface ICampaign {
  id: string;
  active: 'Y' | 'N' | undefined;
  name: string;
  animal: AnimalType;
  animalSize: 'S' | 'M/L' | '' | null;
  campaignType: CampaignType;
  quantity: number;
  flavour: [];
  productConfig: ProductConfig;
  segments: ISegmentConfig[];
  products: ICampaignProduct[];
  campaignUrl: string;
  campaignImageUrl: string;
  language: string;
  country: string;
  description: string;
  endDate: Date | string | null;
  startDate: Date | string | null;
}
