<div class="pb-20"
     *transloco="let t; read: 'formData'">
  <form [formGroup]="shippingAddress"
        novalidate>
    <div>
      <div class="d-flex-column">
        <div class="column-100"
             [hidden]="countryCode !== 'IT'">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.streetAddress.label') }} </mat-label>
            <input type="text"
                   formControlName="streetAddress"
                   autocomplete="off"
                   class="text-uppercase shipping-street-address"
                   placeholder="{{ t('shippingAddress.streetAddress.placeholder') }}"
                   maxlength="35"
                   matInput
                   #streetAddressItaly>
            <mat-error *ngIf="checkFormFieldValidation('streetAddress')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex-column">
        <div class="column-75"
             [hidden]="countryCode === 'IT'">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.streetAddress.label') }} </mat-label>
            <input type="text"
                   formControlName="streetAddress"
                   autocomplete="off"
                   class="text-uppercase shipping-street-address"
                   placeholder="{{ t('shippingAddress.streetAddress.placeholder') }}"
                   matInput
                   #streetAddress>
            <mat-error *ngIf="checkFormFieldValidation('streetAddress')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>

          <div class="auto-complete-placeholder"
               #autoCompletePlaceholder>

          </div>
        </div>

        <div class="column-25"
             [hidden]="countryCode === 'IT'">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.doorNumber.label') }} </mat-label>
            <input type="text"
                   formControlName="doorNumber"
                   class="text-uppercase"
                   placeholder="{{ t('shippingAddress.doorNumber.placeholder') }}"
                   autocomplete="off"
                   matInput
                   #doorNumber>
            <mat-error *ngIf="checkFormFieldValidation('doorNumber')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex-column">
        <div class="column-50">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.postalCode.label') }} </mat-label>
            <input type="text"
                   formControlName="postalCode"
                   maxlength="20"
                   class="text-uppercase"
                   placeholder="{{ t('shippingAddress.postalCode.label') }}"
                   matInput
                   #postalCode>
            <mat-error *ngIf="checkFormFieldValidation('postalCode')">
              {{ t('error.invalid') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column-50">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.city.label') }} </mat-label>
            <input type="text"
                   formControlName="city"
                   class="text-uppercase"
                   placeholder="{{ t('shippingAddress.city.placeholder') }}"
                   matInput
                   #city>
            <mat-error *ngIf="checkFormFieldValidation('city')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex-column">
        <div class="column-50"
             *ngIf="shippingCountryCode === 'IT'">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.province.label') }} </mat-label>
            <mat-select formControlName="province"
                        #provinceItaly>
              <mat-option *ngFor="let province of italyProvinces"
                          [value]="province.code">
                {{ province.code }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column-50">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.country.label') }} </mat-label>
            <mat-select (selectionChange)="userChangedCountry($event.value)"
                        class="text-uppercase"
                        formControlName="country">
              <mat-option *ngFor="let country of countries"
                          class="text-uppercase"
                          [value]="country.code">
                {{ country.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="checkFormFieldValidation('country')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex-column">
        <div class="column-100">
          <mat-form-field appearance="outline">
            <mat-label> {{ t('shippingAddress.carrierNote.label') }} </mat-label>
            <textarea formControlName="carrierNote"
                      maxlength="249"
                      placeholder="{{ t('shippingAddress.carrierNote.placeholder') }}"
                      matInput>
                </textarea>
            <mat-error *ngIf="checkFormFieldValidation('carrierNote')">
              {{ t('error.required') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div class="d-flex-column"
         style="display: none;"
         #errorMessageElement>
      <div class="column-50">
        <mat-error>
          {{ t('shippingAddress.errorMessage') }}
        </mat-error>
      </div>
    </div>

    <div [ngClass]="{'d-none': hideSaveButton}"
         class="d-flex-column">
      <div class="column-25">
        <button (click)="emitFormValues()"
                #saveShippingAddressBtn
                class="almo-btn almo-btn__primary">
          {{ saveButtonText ? saveButtonText : t('save.label') }}

        </button>
      </div>
    </div>
  </form>
</div>
