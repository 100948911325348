import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ICampaign } from 'models/campaign.model';
import { IReceipt } from 'models/receipt.model';

@Injectable({
  providedIn: 'root',
})
export class ReceiptUploadService {
  constructor(private readonly aff: AngularFireFunctions) {}

  async getCampaign(country: string): Promise<ICampaign[]> {
    const campaigns = this.aff.httpsCallable('cruscott-getCampaigns')({
      country,
    });
    const response: ICampaign[] = await campaigns.toPromise();

    return response.filter((campaign: ICampaign) => campaign.active === 'Y');
  }

  async createReceipt(receipt: IReceipt): Promise<IReceipt> {
    return this.aff
      .httpsCallable('cruscott-manageReceipt')({
        receipt: {
          ...receipt,
        },
      })
      .toPromise();
  }

  async getReceipts(email: string): Promise<IReceipt[]> {
    if (!email) {
      return Promise.reject();
    }

    return this.aff
      .httpsCallable('cruscott-getReceipts')({ email })
      .toPromise();
  }

  isReceiptExists(
    email: string,
    campaignId: string,
    doorNumber: string,
    streetAddress: string,
  ): Promise<any> {
    return this.aff
      .httpsCallable('cruscott-isReceiptExists')({
        email,
        campaignId,
        doorNumber,
        streetAddress,
      })
      .toPromise();
  }
}
