<main class="bg-white w-100 receipt-upload-component"
      *transloco="let t;">

  <!-- show receipt upload on sucessful campaign API response -->
  <div class="stepper-form"
       *ngIf="!finalCheckout">
    <form [formGroup]="receiptForm"
          (keydown.enter)="$event.preventDefault()">
      <app-receipt-stepper [linear]="true"
                           [showLoadingNextButton]="showLoadingNextButton"
                           [hideButtons]="hideStepperButtons"
                           (nextButton)="clickedNextButton($event)"
                           #receiptStepper>

        <cdk-step [stepControl]="emailStepControl">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.emailTitle') }}
          </div>
          <mat-form-field class="fadeIn w-100 mt-10 receipt-email-field"
                          appearance="outline">
            <mat-label> {{ t('formData.profile.email.label') }} </mat-label>
            <input type="email"
                   formControlName="email"
                   placeholder="{{ t('formData.profile.email.label') }}"
                   matInput>
            <mat-error *ngIf="checkFormFieldValidation('email')">
              {{ t('formData.error.required') }}
            </mat-error>
          </mat-form-field>

          <div class="row privacy-consent fadeIn">
            <div class="col-md-12">
              <div class="almo-fs-14"
                   [innerHTML]="t('privacy.consent.statement', { privacyPolicy: privacyHTML })">
              </div>

              <div class="ml-10 almo-fs-14 mt-15 py-10">
                <mat-checkbox color="primary"
                              formControlName="consentProjects">
                  <span [innerHTML]="t('privacy.consent.privacy', { privacyPolicy: privacyHTML })">

                  </span>
                </mat-checkbox>
              </div>

              <div class="ml-10 almo-fs-14 pb-10">
                <mat-checkbox color="primary"
                              formControlName="consentFoundation">
                  {{ t('privacy.consent.foundation') }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </cdk-step>

        <cdk-step [stepControl]="animalTypeStepControl">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.selectPet') }}
          </div>

          <mat-radio-group class="fadeIn d-flex my-10 flex-column"
                           formControlName="animalType">
            <mat-radio-button color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              [value]="animalType.cat">
              {{ t('formData.petType.cat').toLowerCase() }}
            </mat-radio-button>

            <mat-radio-button color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              [value]="animalType.sdog">
              {{ t('formData.petType.smallDog').toLowerCase() }}
            </mat-radio-button>

            <mat-radio-button color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              [value]="animalType.mldog">
              {{ t('formData.petType.mediumDog').toLowerCase() }}
            </mat-radio-button>
          </mat-radio-group>

          <div class="fadeIn no-active-campaigns py-20"
               *ngIf="noCampaignFetched">
            <mat-error>
              {{ t('pages.campaign.noCampaignsAnimal') }}
            </mat-error>
          </div>

        </cdk-step>


        <cdk-step [stepControl]="receiptForm.get('animalSize')">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.lifeStage') }}
          </div>

          <mat-radio-group class="fadeIn d-flex my-10 flex-column"
                           formControlName="animalSize">
            <mat-radio-button *ngIf="isCat"
                              color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              value="kitten">
              {{ t('formData.profile.animalAge.kitten').toLowerCase() }}
            </mat-radio-button>

            <mat-radio-button *ngIf="isDog"
                              color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              value="puppy">
              {{ t('formData.profile.animalAge.puppy').toLowerCase() }}
            </mat-radio-button>

            <mat-radio-button color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              value="adult">
              {{ t('formData.profile.animalAge.adult').toLowerCase() }}
            </mat-radio-button>

            <mat-radio-button color="primary"
                              class="mt-20 mr-30 text-capitalize"
                              value="senior">
              {{ t('formData.profile.animalAge.senior').toLowerCase() }}
            </mat-radio-button>
          </mat-radio-group>

        </cdk-step>



        <cdk-step [stepControl]="receiptForm.get('selectedProducts')">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.selectPurchasedProducts') }}
          </div>

          <mat-radio-group class="fadeIn d-flex pr-30 mt-10 mb-10 flex-column"
                           formControlName="selectedProducts">
            <mat-radio-button *ngFor="let product of productSelectionItems"
                              color="primary"
                              class="mt-20 selected-product pr-30 w-100 text-capitalize"
                              [value]="product">
              {{ product.description }}
            </mat-radio-button>
          </mat-radio-group>

          <div class="validating-receipt pt-15 pl-20"
               *ngIf="showLoading">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div class="duplicate-receipt pt-20"
               *ngIf="isReceiptDuplicate">
            <mat-error *ngIf="isReceiptDuplicateType === 'EMAIL'">
              {{ t('pages.campaign.emailExists') }}
            </mat-error>
          </div>

        </cdk-step>


        <cdk-step [stepControl]="receiptForm.get('receiptName')">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.uploadProofPurchase') }}
          </div>

          <p class="fadeIn mb-20 text-purple opacity-6">
            {{ t('pages.campaign.itCanBeReceiptOrPhoto') }}
          </p>

          <app-receipt-file-upload type="receipt"
                                   (fileUploaded)="receiptUploaded($event)"
                                   (changeFile)="receiptChangeFile()"
                                   storagePath="receipts"
                                   [fileName]="receiptFileName"
                                   formControlName="receiptUrl">
          </app-receipt-file-upload>

        </cdk-step>


        <cdk-step [stepControl]="receiptForm.get('selectedFlavour')">
          <div class="fadeIn stepper-title almo-fs-32"
               *ngIf="!isCatLitterFreeProduct">
            {{ t('pages.campaign.chooseFlavourPet') }}
          </div>
          <div class="fadeIn stepper-title almo-fs-32"
               *ngIf="isCatLitterFreeProduct">
            {{ t('pages.campaign.chooseProduct') }}
          </div>

          <mat-radio-group class="fadeIn mt-md-20 d-flex mb-10 w-100 flex-wrap"
                           formControlName="selectedFlavour">
            <mat-radio-button *ngFor="let product of freeItems"
                              color="primary"
                              class="selected-flavour mt-10 mr-30"
                              [value]="product">
              <img *ngIf="product?.card_image_main"
                   [alt]="product?.description"
                   [src]="product?.card_image_main" />
              <span class="pl-10">
                {{ getProductDescription(product.description) }}
              </span>
              <span class="pl-1"
                    *ngIf="isCatLitter(product)">
                - {{ product?.weight }}
              </span>
            </mat-radio-button>
          </mat-radio-group>

        </cdk-step>

        <cdk-step [stepControl]="addressStepOneControl">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('formData.shippingAddress.title') }}
          </div>

          <div class="fadeIn pt-md-20">
            <div class="pb-20">
              <mat-radio-group formControlName="addressType">
                <mat-radio-button color="primary"
                                  class="mt-10 mr-30"
                                  value="personal">
                  {{ t('formData.shippingAddress.addressType.personal') }}
                </mat-radio-button>
                <mat-radio-button color="primary"
                                  class="mt-10"
                                  value="work">
                  {{ t('formData.shippingAddress.addressType.work') }}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="fadeIn"
                 *ngIf="isWorkAddress">
              <mat-form-field class="w-100"
                              appearance="outline">
                <mat-label> {{ t('formData.shippingAddress.businessName.label') }} </mat-label>
                <input type="text"
                       formControlName="businessName"
                       placeholder="{{ t('formData.shippingAddress.businessName.placeholder') }}"
                       matInput>
                <mat-error *ngIf="checkFormFieldValidation('businessName')">
                  {{ t('formData.error.required') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="d-flex-column">
              <div class="column-50">
                <mat-form-field class="w-100"
                                appearance="outline">
                  <mat-label> {{ t('formData.profile.firstName.label') }} </mat-label>
                  <input type="text"
                         formControlName="firstName"
                         placeholder="{{ t('formData.profile.firstName.placeholder') }}"
                         matInput>
                  <mat-error *ngIf="checkFormFieldValidation('firstName')">
                    {{ t('formData.error.required') }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="column-50">
                <mat-form-field class="w-100"
                                appearance="outline">
                  <mat-label> {{ t('formData.profile.lastName.label') }} </mat-label>
                  <input type="text"
                         formControlName="lastName"
                         placeholder=" {{ t('formData.profile.lastName.placeholder') }}"
                         matInput>
                  <mat-error *ngIf="checkFormFieldValidation('lastName')">
                    {{ t('formData.error.required') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="d-flex-column">
              <div class="column-50">
                <div class="d-flex-column align-mobile">
                  <div class="column-25 align-mobile">
                    <mat-form-field class="w-100 phone-field-height"
                                    appearance="outline">
                      <mat-label>Code</mat-label>
                      <mat-select formControlName="phoneCode">
                        <mat-option [value]="country.countryPhoneCode"
                                    *ngFor="let country of countries">
                          {{ country.countryPhoneCode }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="column-75 align-mobile">
                    <mat-form-field class="w-100 phone-field-height"
                                    appearance="outline">
                      <mat-label> {{ t('formData.profile.phoneNumber.label') }} </mat-label>
                      <input type="text"
                             formControlName="phoneNumber"
                             maxlength="15"
                             placeholder=" {{ t('formData.profile.phoneNumber.placeholder') }}"
                             matInput>
                      <mat-error *ngIf="checkFormFieldValidation('phoneNumber')">
                        {{ t('formData.error.required') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cdk-step>


        <cdk-step [stepControl]="receiptForm.get('shippingAddress')">
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('formData.shippingAddress.title') }}
          </div>

          <div class="fadeIn pt-20"
               *ngIf="showShippingAddress">
            <app-almo-shipping-address [address]="null"
                                       [hideSaveButton]="true"
                                       [saveButtonText]="t('buttons.next')"
                                       (invalidAddress)="handleShippingInvalidAddress()"
                                       (validAddress)="saveShippingValidAddress($event)"
                                       [countryCode]="countryCode"></app-almo-shipping-address>
          </div>
        </cdk-step>


        <cdk-step>
          <div class="fadeIn stepper-title almo-fs-32">
            {{ t('pages.campaign.orderSummary') }}
          </div>

          <div class="py-20 receipt-shipping-error"
               *ngIf="isReceiptDuplicate && !showLoading">
            <mat-error *ngIf="isReceiptDuplicateType !== 'EMAIL'">
              {{ t('pages.campaign.addressExists') }}
            </mat-error>
          </div>

          <div class="validating-receipt py-15 pl-20"
               *ngIf="showLoading">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div class="fadeIn mt-20">
            <div class="d-flex-column">
              <div class="column-50">
                <div class="summary-details">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">mark_email_unread</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong> {{ t('pages.profile.emailAddress') }} </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(0)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <div> {{ receiptForm.get('email').value }} </div>
                  </div>
                </div>

                <div class="summary-details pt-25">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">phone</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong> {{ t('formData.profile.phoneNumber.label') }} </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(6)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <div> {{ shippingAddress?.phoneCode }} {{ shippingAddress?.phoneNumber }} </div>
                  </div>
                </div>

                <div class="summary-details pt-25">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">local_shipping</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong> {{ t('formData.shippingAddress.title') }} </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(7)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>

                    <div *ngIf="shippingAddress.businessName">
                      {{ shippingAddress.businessName }}
                    </div>
                    <div> {{ shippingAddress?.firstName }} {{ shippingAddress?.lastName }}</div>
                    <div> {{ shippingAddress?.doorNumber }} {{ shippingAddress?.streetAddress }} </div>
                    <div> {{ shippingAddress?.postalCode }} {{ shippingAddress?.city }} {{ shippingAddress?.province }}
                    </div>
                  </div>
                </div>

                <div class="summary-details pt-25"
                     *ngIf="shippingAddress?.carrierNote">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">description</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong>
                          {{ t('formData.shippingAddress.carrierNote.label') }}
                        </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(5)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>

                    <div> {{ shippingAddress?.carrierNote }} </div>
                  </div>
                </div>
              </div>
              <div class="column-50">
                <div class="summary-details border-bottom pt-25 pt-md-0">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong>
                          {{ t('pages.campaign.purchasedProducts') }}
                        </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(3)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>

                    <div class="almo-fs-16">
                      <ol class="ml-n25 mb-5">
                        <li> {{ receiptForm.get('selectedProducts').value?.description }} </li>
                      </ol>
                    </div>
                    <div class="summary-uploaded-receipt pb-15 pt-10">
                      <a class="d-flex almo-fs-14 align-content-center"
                         [href]="receiptForm.get('receiptUrl').value"
                         target="_blank"
                         rel="noopener">
                        {{ t('pages.promotions.viewReceipt') }} <mat-icon [inline]="true"
                                  class="ml-10">launch
                        </mat-icon>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="summary-details pt-25"
                     *ngIf="receiptForm.get('selectedFlavour').value">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong> {{ t('pages.campaign.selectedFlavour') }} </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(5)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>

                    <div>
                      {{ receiptForm.get('selectedFlavour').value?.description }} -
                      {{ receiptForm.get('selectedFlavour').value?.weight }}
                    </div>
                  </div>
                </div>

                <div class="summary-details pt-25 pb-50">
                  <div class="icon">
                    <mat-icon class="material-icons-outlined">pets</mat-icon>
                  </div>
                  <div class="details">
                    <div class="details--heading">
                      <p>
                        <strong> {{ t('pages.campaign.selectPet').replace(':', '') }} </strong>
                      </p>
                      <div class="details--heading--icon"
                           (click)="moveToStep(2)">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>

                    <div>
                      {{ selectedAnimalDetails }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="confirm-order">
              <div class="confirm-order__btn">
                <button (click)="confirmAndPlaceOrder()"
                        [disabled]="clickedConfirmOrder || isReceiptDuplicate"
                        class="w-100 almo-btn almo-btn__primary">
                  {{ t('pages.campaign.confirmAndPlaceOrder') }}
                </button>
              </div>
            </div>
          </div>
        </cdk-step>
      </app-receipt-stepper>
    </form>
  </div>

  <div class="show-checkout-screen py-40 px-20 fadeIn"
       *ngIf="finalCheckout">
    <div>
      <h3> {{ t('pages.campaign.success') }} </h3>

      <p class="pt-20 fadeIn"
         *ngIf="orderNumber"> {{ t('formData.strings.orderNumber') }}:
        <strong>{{ orderNumber }}</strong>
      </p>

      <!-- Below lines are for aug 2021 holiday notification
      <p class="mt-15">{{ t('formData.holidayNotification.line1') }}</p>
      <p class="mt-15">{{ t('formData.holidayNotification.line2') }}</p>
      <p class="mt-15">{{ t('formData.holidayNotification.line3') }}</p>
      <p class="mt-15">{{ t('formData.holidayNotification.line4') }}</p>
      -->
      <p class="almo-fs-16 py-30"> {{ t('pages.campaign.orderSubmitted') }} </p>
    </div>

    <hr class="opacity-3" />

    <div class="mt-20 text-center fadeIn">
      <a target="_blank"
         [href]="almoHomePageLink"
         rel="noopener">
        {{ t('pages.campaign.discoverAlmoNatureProducts') }}
      </a>
    </div>
  </div>
</main>
