import {
  isNotAnonymous,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

/*
AngularFire Router Guards
https://github.com/angular/angularfire/blob/master/docs/auth/router-guards.md
*/
export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(
    isNotAnonymous,
    map((loggedIn) => loggedIn || redirect),
  );

export const redirectUnauthorizedToLogin = (data) => {
  const { params, _routerState } = data;
  return redirectUnauthorizedTo(
    `/${params.language}/account/login?redirectUrl=${_routerState.url}`,
  );
};
