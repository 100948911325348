import { availableLanguages } from './languages.constant';

export interface ICountryPostalRegEx {
  code: string;
  name: string;
  postal?: RegExp;
  countryPhoneCode?: string;
}

export const webAppCountryPostalRegEx: ICountryPostalRegEx[] = [
  {
    code: 'BE',
    name: 'Belgium',
    postal: /[0-9]{4}/g,
    countryPhoneCode: '+32',
  },
  {
    code: 'CA',
    name: 'Canada',
    postal: /[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]/g,
    countryPhoneCode: '+1',
  },
  {
    code: 'SV',
    name: 'El Salvador',
    postal: /[0-9]{4}/g,
    countryPhoneCode: '+503',
  },
  {
    code: 'FI',
    name: 'Finland',
    postal: /[0-9]{5}/g,
    countryPhoneCode: '+358',
  },
  {
    code: 'FR',
    name: 'France',
    postal: /[0-9]{5}/g,
    countryPhoneCode: '+33',
  },
  {
    code: 'DE',
    name: 'Germany',
    postal: /[0-9]{5}/g,
    countryPhoneCode: '+49',
  },
  {
    code: 'IT',
    name: 'Italy',
    postal: /[0-9]{5}/g,
    countryPhoneCode: '+39',
  },
  {
    code: 'NL',
    name: 'Netherlands',
    postal: /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i,
    countryPhoneCode: '+31',
  },
  {
    code: 'ES',
    name: 'Spain',
    postal: /[0-9]{5}/g,
    countryPhoneCode: '+34',
  },
  {
    code: 'CH',
    name: 'Switzerland',
    postal: /[0-9]{4}/g,
    countryPhoneCode: '+41',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    postal:
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g,
    countryPhoneCode: '+44',
  },
  {
    code: 'US',
    name: 'United States of America',
    postal: /^\d{5}(?:[-\s]\d{4})?$/g,
    countryPhoneCode: '+1',
  },
];

const webAppCountries = availableLanguages.map((lang) => lang.countryCode);

export const countryPostalRegEx = webAppCountryPostalRegEx.filter((country) => {
  return webAppCountries.includes(country.code);
});
