import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/auth';
import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/database';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  REGION as FUNCTIONS_REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: FUNCTIONS_REGION,
      useValue: environment.firebaseConfig.region,
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? environment.emulator.auth : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.emulator
        ? environment.emulator.firestore
        : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.emulator
        ? environment.emulator.databaseUrl
        : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulator
        ? environment.emulator.functions
        : undefined,
    },
  ],
})
export class FirebaseModule {}
