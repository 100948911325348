import { Injectable } from '@angular/core';
import { IShippingAddress } from 'models/shipping-address.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private googleApi = `https://maps.googleapis.com/maps/api/js?key=${environment.gMapsApiKey}&libraries=places`;
  private scriptTagInserted = false;

  constructor() {}

  insertScriptTag(): void {
    const scriptTags = document.getElementsByTagName(
      'script'
    ) as HTMLCollectionOf<HTMLScriptElement>;
    // to convert HTMLCollectionOf of HTMLScript elements into array
    const scriptTagsArray = [...Array.from(scriptTags)];
    const isScriptAlreadyExists = scriptTagsArray.some(
      (scriptTag) => scriptTag.getAttribute('src') === this.googleApi
    );
    if (!isScriptAlreadyExists && !this.scriptTagInserted) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = this.googleApi;
      scriptElement.defer = true;
      document.body.appendChild(scriptElement);
      this.scriptTagInserted = true;
    }
  }

  removeScriptTag(): void {
    const scriptTags = document.getElementsByTagName(
      'script'
    ) as HTMLCollectionOf<HTMLScriptElement>;
    // to convert HTMLCollectionOf of HTMLScript elements into array
    const scriptTagsArray = [...Array.from(scriptTags)];
    const scriptExists = scriptTagsArray.find(
      (scriptTag) => scriptTag.getAttribute('src') === this.googleApi
    );

    // automatically added by google autocomplete
    const pacContainerTags = document.getElementsByClassName(
      'pac-container'
    ) as HTMLCollectionOf<HTMLScriptElement>;

    // convert list to array
    const pacContainerTagsArray = [...Array.from(pacContainerTags)];

    if (pacContainerTagsArray.length > 0) {
      pacContainerTagsArray.forEach((container) => container.remove());
    }

    if (scriptExists) {
      scriptExists.remove();
    }
  }

  /*
  takes auto complete place
  and returns IShippingAddress
  */
  onAddressChange(place: any): IShippingAddress {
    const components = place.address_components;

    const streetNumberComponent = this.findAddressComponentByTypes(
      components,
      'street_number'
    );

    const streetAddressComponent = this.findAddressComponentByTypes(
      components,
      'street_address'
    );

    const routeComponent = this.findAddressComponentByTypes(
      components,
      'route'
    );

    const cityComponent = this.findAddressComponentByTypes(
      components,
      'locality',
      ['administrative_area_level_3']
    );

    const postalCodeComponent = this.findAddressComponentByTypes(
      components,
      'postal_code'
    );

    const provinceComponent = this.findAddressComponentByTypes(
      components,
      'administrative_area_level_2'
    );

    const stateComponent = this.findAddressComponentByTypes(
      components,
      'administrative_area_level_1'
    );

    const countryComponent = this.findAddressComponentByTypes(
      components,
      'country',
      ['political']
    );

    const streetAddress = `${
      streetAddressComponent ? streetAddressComponent?.long_name : ''
    } ${routeComponent ? routeComponent?.long_name : ''}`;

    return {
      country: countryComponent?.long_name,
      streetAddress: streetAddress.trim(),
      doorNumber:
        streetNumberComponent?.short_name ?? streetNumberComponent?.long_name,
      city: cityComponent?.long_name,
      province: provinceComponent?.short_name,
      postalCode: postalCodeComponent?.long_name,
      coordinates: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    } as IShippingAddress;
  }

  /*
  takes list of address components
  checks for componentType
  if not found, checks for fallbacks
  returns matched value
  */

  findAddressComponentByTypes(
    components = [],
    componentType = 'locality',
    fallbacks = []
  ): any {
    const [match = null] = components.filter((component) => {
      if (!component || !component.types || !Array.isArray(component.types)) {
        return false;
      }

      return component.types.includes(componentType);
    });

    if (!fallbacks || !fallbacks.length) {
      return match;
    }

    const fallbackMatches = components.filter((component) => {
      if (!component || !component.types || !Array.isArray(component.types)) {
        return false;
      }

      return component.types.some((e) => fallbacks.includes(e));
    });

    const allMatches = [match, ...fallbackMatches].filter(Boolean);
    const [firstMatch] = allMatches;

    return firstMatch;
  }
}
