import { IShippingAddress } from 'models/shipping-address.model';
import { getDateString, normalizeDate } from '../utils/normalize-date.util';

export interface IUserConsent {
  foundation: boolean;
  privacy: boolean;
  marketingSubscriptionPreference?: {
    almo?: boolean;
    fondazione?: boolean;
    petOwner?: {
      cat?: boolean;
      dog?: boolean;
    };
  };
}

export interface IUser {
  uid: string;
  email: string;
  createdDate: Date | any;
  firstName?: string;
  lastName?: string;
  photoURL?: string;
  phoneNumber?: number | any;
  gender?: 'male' | 'female' | '';
  language?: string;
  country?: string;
  dateOfBirth?: Date | any;
  shippingAddress?: IShippingAddress;
  userConsent?: IUserConsent;
  hasPasswordProvider?: boolean;
  verificationEmailStatus?: any;
}

export interface IDeleteUser extends IUser {
  reason: string;
  consent: boolean;
  accountDisabled?: boolean;
}

export class User implements IUser {
  uid: string;
  email: string;
  createdDate: Date | any;
  firstName?: string;
  lastName?: string;
  photoURL?: string;
  phoneNumber?: number | any;
  gender?: 'male' | 'female' | '';
  language?: string;
  country?: string;
  dateOfBirth?: Date | any;
  shippingAddress?: IShippingAddress;
  userConsent?: IUserConsent;
  hasPasswordProvider?: boolean;
  verificationEmailStatus?: any;

  constructor(data: IUser) {
    this.uid = data.uid;
    this.email = data.email;
    this.createdDate = normalizeDate(data.createdDate) ?? '';
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.photoURL = data.photoURL ?? '';
    this.phoneNumber = data.phoneNumber ?? '';
    this.gender = data.gender ?? '';
    this.language = data.language ?? '';
    this.country = data.country ?? '';
    this.dateOfBirth = normalizeDate(data.dateOfBirth) ?? '';
    this.shippingAddress = data.shippingAddress ?? User.emptyShippingAddress();
    this.userConsent = data.userConsent ?? User.emptyUserConsent();
    this.hasPasswordProvider = data.hasPasswordProvider ?? false;
    this.verificationEmailStatus = data.verificationEmailStatus ?? null;
  }

  static emptyUserConsent(): IUserConsent {
    return {
      foundation: false,
      privacy: false,
    };
  }

  static emptyShippingAddress(): IShippingAddress {
    return {
      addressType: '',
      businessName: '',
      firstName: '',
      lastName: '',
      country: '',
      phoneNumber: '',
      email: '',
      streetAddress: '',
      doorNumber: '',
      city: '',
      province: '',
      postalCode: '',
      carrierNote: '',
      coordinates: {
        lat: 0,
        lng: 0,
      },
    };
  }

  updateShippingAddress(address: IShippingAddress): User {
    return new User({
      ...this.toJson(),
      shippingAddress: address,
    });
  }

  updateUserConsent(consent: IUserConsent): User {
    return new User({
      ...this.toJson(),
      userConsent: {
        foundation: consent.foundation ?? false,
        privacy: consent.privacy ?? false,
      },
    });
  }

  toJson(): IUser {
    return {
      uid: this.uid,
      email: this.email,
      createdDate: getDateString(this.createdDate),
      firstName: this.firstName,
      lastName: this.lastName,
      photoURL: this.photoURL,
      phoneNumber: this.phoneNumber,
      gender: this.gender,
      language: this.language,
      country: this.country,
      dateOfBirth: getDateString(this.dateOfBirth),
      shippingAddress: this.shippingAddress,
      userConsent: this.userConsent,
      hasPasswordProvider: this.hasPasswordProvider,
      verificationEmailStatus: this.verificationEmailStatus,
    };
  }
}
