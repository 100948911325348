import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { countryPostalRegEx } from 'constants/countries-postal.constant';
import {
  availableLanguages,
  defaultLanguage,
} from 'constants/languages.constant';
import { emailRegexPattern } from 'constants/regexp.constant';
import { getBrowserDetails } from 'helpers/browser-version.helper';
import { ICampaign, IProduct, ProductConfig } from 'models/campaign.model';
import { IReceipt, ReceiptStatus } from 'models/receipt.model';
import { IShippingAddress } from 'models/shipping-address.model';
import { Subscription } from 'rxjs';
import { LanguageService } from 'services/language.service';
import { getDateTimeString } from 'utils/normalize-date.util';
import { ReceiptUploadService } from './receipt-upload.service';

export enum Animal {
  Cat = 'cat',
  Dog = 'dog',
}

export enum AnimalType {
  Cat = 'cat',
  SDog = 'small-dog',
  MLDog = 'medium-or-large-dog',
}

export enum ReceiptSteps {
  Email = 'Email Address',
  SelectAnimal = 'Select Animal',
  AnimalSize = 'Select Animal Size',
  PurchasedProduct = 'Select Purchased Product',
  UploadReceipt = 'Upload Receipt',
  SelectFlavour = 'Select Flavour',
  ShippingOne = 'Shipping Step 1',
  ShippingTwo = 'Shipping Step 2',
  OrderPlaced = 'Order Placed',
}

export interface IProductSelection {
  description: string;
  petAge: string;
  quantity: number;
  relatedCampaignId: string;
}

@Component({
  selector: 'app-receipt-upload',
  templateUrl: './receipt-upload.component.html',
  styleUrls: ['./receipt-upload.component.scss'],
})
export class ReceiptUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  locale: string = null;

  @Input()
  animal: string = null;

  @ViewChild('receiptStepper')
  private receiptStepper: CdkStepper;

  animalType = {
    cat: AnimalType.Cat,
    sdog: AnimalType.SDog,
    mldog: AnimalType.MLDog,
  };

  countries = countryPostalRegEx;
  countryCode = null;
  countryPhoneCode = null;
  campaigns: ICampaign[] = null;
  selectedCampaign: ICampaign = null;
  selectedPetCampaigns: ICampaign[] = [];
  productSelectionItems: IProductSelection[] = [];
  freeItems: IProduct[] = [];
  noCampaignFetched = false;
  hideStepperButtons = false;
  finalCheckout = false;
  orderNumber = null;
  clickedConfirmOrder = false;
  documentId = null;
  isReceiptDuplicate = false;
  isReceiptDuplicateType = null;
  showLoading = false;
  showShippingAddress = false;
  clickedNextAtEmail = false;
  isCatLitterFreeProduct = false;

  receiptForm: FormGroup;
  receiptFileName = null;
  subscription = new Subscription();

  constructor(
    private languageService: LanguageService,
    private receiptService: ReceiptUploadService,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.animal) {
      this.animal = this.animal.toLowerCase();
    }

    this.initFormBuilder();

    this.subscription.add(
      this.languageService.currentLang$.subscribe((lang) => {
        this.receiptForm.get('language').setValue(lang.locale);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.locale) {
      const findLocale = availableLanguages.find(
        (lang) => lang.locale === this.locale,
      );

      if (findLocale) {
        this.languageService.changeLanguage(this.locale);
      }

      this.countryCode = this.locale.split('-')[1];

      this.countryPhoneCode = this.countries.find(
        (country) => country.code === this.countryCode,
      )?.countryPhoneCode;

      this.receiptService
        .getCampaign(this.countryCode)
        .then((campaigns) => {
          this.campaigns = campaigns;
          this.selectedCampaign = campaigns[0];
        })
        .catch(() => (this.noCampaignFetched = true));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initFormBuilder(): void {
    this.receiptForm = this.formBuilder.group({
      id: [null, Validators.required],
      email: ['', [Validators.required, Validators.pattern(emailRegexPattern)]],
      language: [this.locale],
      createdDate: [getDateTimeString(new Date()), Validators.required],
      campaign: [this.selectedCampaign],
      status: [null],
      receipt: [true],
      animalType: [null, Validators.required],
      animalSize: [null, Validators.required],
      selectedProducts: [null, Validators.required],
      receiptName: [null, Validators.required],
      receiptUrl: [null, Validators.required],
      receiptType: [null, Validators.required],
      completedStep: [null, Validators.required],
      selectedFlavour: [null, Validators.required],
      shippingAddress: ['', Validators.required],
      addressType: ['personal', Validators.required],
      businessName: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneCode: [this.countryPhoneCode, Validators.required],
      phoneNumber: [null, Validators.required],
      consentProjects: [null, Validators.requiredTrue],
      consentFoundation: [null],
      browserDetails: [getBrowserDetails()],
    });

    this.formSubscriptions();
  }

  get currentLocale(): string {
    const lang = this.languageService.currentLang$.getValue();

    if (lang?.locale) {
      return lang.locale;
    }

    return defaultLanguage.locale;
  }

  get showLoadingNextButton(): boolean {
    // show loading until document id is received
    const id = this.receiptForm.get('id');
    return this.clickedNextAtEmail && this.emailStepControl.valid && id.invalid;
  }

  get isCat(): boolean {
    const animalType = this.receiptForm.get('animalType').value;
    return animalType === AnimalType.Cat;
  }

  get isDog(): boolean {
    const animalType = this.receiptForm.get('animalType').value;
    return animalType === AnimalType.SDog || animalType === AnimalType.MLDog;
  }

  get animalTypeStepControl(): AbstractControl {
    return this.formBuilder.group({
      id: this.receiptForm.get('id'),
      animalType: this.receiptForm.get('animalType'),
    });
  }

  get shippingAddress(): IShippingAddress {
    const stepOne = this.addressStepOneControl.value;
    const stepTwo = this.receiptForm.get('shippingAddress').value;
    return {
      ...stepOne,
      ...stepTwo,
    } as IShippingAddress;
  }

  get isWorkAddress(): boolean {
    return this.receiptForm.get('addressType').value === 'work';
  }

  get addressStepOneControl(): AbstractControl {
    return this.formBuilder.group({
      addressType: this.receiptForm.get('addressType'),
      businessName: this.receiptForm.get('businessName'),
      firstName: this.receiptForm.get('firstName'),
      lastName: this.receiptForm.get('lastName'),
      phoneCode: this.receiptForm.get('phoneCode'),
      phoneNumber: this.receiptForm.get('phoneNumber'),
    });
  }

  get emailStepControl(): AbstractControl {
    return this.formBuilder.group({
      email: this.receiptForm.get('email'),
      consentProjects: this.receiptForm.get('consentProjects'),
      consentFoundation: this.receiptForm.get('consentFoundation'),
    });
  }

  get almoHomePageLink(): string {
    return this.getAlmoNaturePageLink();
  }

  get privacyStatement(): HTMLElement {
    return this.translocoService.translate('privacy.statement', {
      cookiePolicy: this.cookieHTML,
      privacyPolicy: this.privacyHTML,
    });
  }

  get privacyHTML(): string {
    const privacyLink = this.getAlmoNaturePageLink('privacy-policy');
    const privacyTranslation = this.translocoService.translate(
      'privacy.privacyPolicy',
    );
    return `<a target="_blank" href="${privacyLink}">${privacyTranslation}</a>`;
  }

  get cookieHTML(): string {
    const cookieLink = this.getAlmoNaturePageLink('cookie-policy');
    const cookieTranslation = this.translocoService.translate(
      'privacy.cookiePolicy',
    );
    return `<a target="_blank" href="${cookieLink}">${cookieTranslation}</a>`;
  }

  get selectedAnimalDetails(): string {
    const animalType = this.receiptForm.get('animalType').value;
    const animalSize = this.receiptForm.get('animalSize').value;
    if (!animalType || !animalSize) {
      return null;
    }

    let animal = 'dog';

    if (animalType === this.animalType.cat) {
      animal = 'cat';
    }

    const animalText = this.translocoService.translate(
      `formData.petType.${animal}`,
    );
    const sizeText = this.translocoService.translate(
      `formData.profile.animalAge.${animalSize}`,
    );

    return `${animalText} - ${sizeText}`;
  }

  formSubscriptions(): void {
    this.subscription.add(
      this.receiptForm
        .get('animalType')
        .valueChanges.subscribe((val: string) => {
          this.receiptForm.get('animalSize').setValue(null);
          if (val) {
            this.selectRelatedCampaigns(val);
          }
        }),
    );

    this.subscription.add(
      this.receiptForm
        .get('addressType')
        .valueChanges.subscribe((val: string) => {
          const businessName = this.receiptForm.get('businessName');
          if (val === 'work') {
            businessName.setValidators(Validators.required);
          } else {
            businessName.clearValidators();
          }
          businessName.updateValueAndValidity();
        }),
    );

    this.subscription.add(
      this.receiptForm.get('email').valueChanges.subscribe(() => {
        this.isReceiptDuplicate = false;
        this.isReceiptDuplicateType = null;
        this.showLoading = false;
      }),
    );

    this.subscription.add(
      this.receiptForm.get('selectedProducts').valueChanges.subscribe((val) => {
        if (val) {
          this.handleProductSelectionChange(val.relatedCampaignId);
        }
      }),
    );

    this.subscription.add(
      this.receiptForm.get('animalSize').valueChanges.subscribe((val) => {
        this.prepareProductSelectionItems(val);
      }),
    );
  }

  isSegmentConfig(campaign: ICampaign): boolean {
    return campaign.productConfig === ProductConfig.Segment;
  }

  isItemConfig(campaign: ICampaign): boolean {
    return campaign.productConfig === ProductConfig.Item;
  }

  prepareProductSelectionItems(lifeStage = null): void {
    if (!this.selectedPetCampaigns?.length) {
      return;
    }

    const products: IProductSelection[] = [];
    this.selectedPetCampaigns.forEach((campaign) => {
      if (this.isSegmentConfig(campaign)) {
        campaign?.segments.map((segment) => {
          segment.descriptions.map((description) => {
            products.push({
              relatedCampaignId: campaign.id,
              description: description,
              petAge: segment?.petAge,
              quantity: segment?.quantity,
            });
          });
        });
      }

      if (this.isItemConfig(campaign)) {
        campaign?.products.map((campaignProduct) => {
          campaignProduct.items.map((item) => {
            products.push({
              relatedCampaignId: campaign.id,
              description: item.description,
              petAge: campaignProduct?.petAge,
              quantity: campaignProduct?.quantity,
            });
          });
        });
      }
    });

    this.productSelectionItems = products;

    // check if products have life stage (petAge) config and
    // select specific products
    if (lifeStage) {
      const productsFilterPetAge = products.filter(
        (product) => product?.petAge?.toLowerCase() === lifeStage,
      );

      const undefinedPetAgeProducts = products.filter(
        (product) => !product?.petAge,
      );

      // Add back products which have petAge as undefined.
      if (productsFilterPetAge?.length) {
        this.productSelectionItems = [
          ...productsFilterPetAge,
          ...undefinedPetAgeProducts,
        ];
      } else {
        this.productSelectionItems = products;
      }
    }
  }

  prepareFreeItems(): void {
    if (!this.selectedCampaign) {
      return;
    }
    const items: IProduct[] = [];
    const selectedProduct = this.receiptForm.get('selectedProducts').value;

    if (this.isSegmentConfig(this.selectedCampaign)) {
      this.selectedCampaign.segments.map((segment) => {
        if (segment.descriptions.includes(selectedProduct.description)) {
          segment.freeItems.map((item) => {
            items.push(item);
          });
          this.isCatLitterFreeProduct = this.hasCatLitterInFreeItems(
            segment.freeItems,
          );
        }
      });
    }

    if (this.isItemConfig(this.selectedCampaign)) {
      this.selectedCampaign.products.map((product) => {
        const hasItem = product.items.find(
          (item) => item.description === selectedProduct.description,
        );
        if (hasItem) {
          product.freeItems.map((item) => items.push(item));
          this.isCatLitterFreeProduct = this.hasCatLitterInFreeItems(
            product.freeItems,
          );
        }
      });
    }

    this.freeItems = items.filter(
      (currentValue, index, array) =>
        array.findIndex((item) => item.item_code === currentValue.item_code) ===
        index,
    );
  }

  hasCatLitterInFreeItems(products: IProduct[]): boolean {
    const catLitterProduct = products.find((p) => this.isCatLitter(p));
    return catLitterProduct ? true : false;
  }

  isCatLitter(item: IProduct): boolean {
    return item.item_code === '76' || item.item_code === '77';
  }

  getProductDescription(description: string): string {
    return description.replace(/undefined/g, '');
  }

  getAlmoNaturePageLink(slug: string = null): string {
    const currentLang = this.languageService.currentLang$.getValue();
    const lang = currentLang.langCode.toLowerCase();
    const country = currentLang.countryCode.toLowerCase();
    const websiteCountryCode =
      lang === country ? lang : `${lang}_${country.toUpperCase()}`;
    const baseUrl = `https://www.almonature.com/${websiteCountryCode}/`;

    if (!slug) {
      return baseUrl;
    }

    return `${baseUrl}${slug}/`;
  }

  handleProductSelectionChange(campaignId: string): void {
    this.selectedCampaign = this.campaigns.find((c) => c.id === campaignId);
    this.receiptForm.get('campaign').setValue(this.selectedCampaign);

    this.showLoading = true;
    this.isReceiptDuplicate = false;

    this.receiptForm.updateValueAndValidity();

    this.checkIfReceiptExists();
    this.prepareFreeItems();
  }

  selectRelatedCampaigns(type: string): void {
    if (!type && !this.campaigns) {
      return;
    }
    let petCampaigns: ICampaign[];
    if (type === AnimalType.Cat) {
      petCampaigns = this.campaigns.filter(
        (campaign) =>
          campaign.animal?.toLowerCase() === type.toLowerCase() &&
          campaign.language === this.locale,
      );
    }

    if (type === AnimalType.SDog) {
      petCampaigns = this.campaigns.filter(
        (campaign) =>
          campaign.animalSize?.toLowerCase() === 's' &&
          campaign.animal?.toLowerCase() === Animal.Dog.toLowerCase() &&
          campaign.language === this.locale,
      );
    }

    if (type === AnimalType.MLDog) {
      petCampaigns = this.campaigns.filter(
        (campaign) =>
          campaign.animalSize?.toLowerCase() === 'm/l' &&
          campaign.animal?.toLowerCase() === Animal.Dog.toLowerCase() &&
          campaign.language === this.locale,
      );
    }

    if (petCampaigns.length) {
      this.selectedPetCampaigns = petCampaigns;
      this.noCampaignFetched = false;
      this.isReceiptDuplicate = false;
      this.showLoading = false;
    } else {
      this.receiptForm.get('animalType').setErrors({ invalid: true });
      this.noCampaignFetched = true;
    }

    this.receiptForm.patchValue({
      selectedProducts: null,
      selectedFlavour: null,
    });

    this.receiptForm.updateValueAndValidity();
  }

  checkIfReceiptExists(type = 'email'): void {
    const campaignId = this.selectedCampaign?.id;
    const email = this.receiptForm.get('email').value;
    const shippingAddress = this.receiptForm.get('shippingAddress').value;
    const doorNumber = shippingAddress?.doorNumber ?? '';
    const streetAddress = shippingAddress?.streetAddress ?? ' ';
    if (type !== 'address') {
      this.receiptForm.get('selectedProducts').setErrors({ invalid: true });
    }

    this.receiptService
      .isReceiptExists(
        type === 'address' ? ' ' : email,
        campaignId,
        doorNumber,
        streetAddress,
      )
      .then((results) => {
        if (results.length) {
          const receiptExists = results[0]?.receiptExists;
          this.isReceiptDuplicate = true;
          this.isReceiptDuplicateType = receiptExists;
          this.saveReceipt();
        } else {
          this.isReceiptDuplicate = false;
          this.isReceiptDuplicateType = null;
          if (type !== 'address') {
            this.receiptForm.get('selectedProducts').setErrors(null);
          }
        }
        this.receiptForm.updateValueAndValidity();
        this.showLoading = false;
      })
      .catch(() => {
        if (type !== 'address') {
          this.receiptForm.get('selectedProducts').setErrors(null);
        }
        this.showLoading = false;
        this.isReceiptDuplicate = false;
        this.isReceiptDuplicateType = null;
      });
  }

  checkFormFieldValidation(field: string): boolean {
    return (
      this.receiptForm.get(field).touched && this.receiptForm.get(field).invalid
    );
  }

  receiptUploaded(event: any): void {
    this.receiptFileName = event?.fileName;
    this.receiptForm.get('receiptName').setValue(event?.fileName);
    this.receiptForm.get('receiptType').setValue(event?.fileType);
    this.receiptForm.get('completedStep').setValue(ReceiptSteps.UploadReceipt);
    this.receiptForm.updateValueAndValidity();
  }

  receiptChangeFile(): void {
    // Reset receipt form
    this.receiptForm.patchValue({
      receiptUrl: null,
      receiptName: null,
      receiptType: null,
    });
    this.receiptForm.updateValueAndValidity();
  }

  saveShippingValidAddress(event: any): void {
    this.receiptForm.get('shippingAddress').setValue(event);
    this.receiptForm.get('shippingAddress').setErrors(null);
    this.receiptForm.get('shippingAddress').updateValueAndValidity();
    this.receiptForm.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  handleShippingInvalidAddress(): void {
    this.receiptForm.get('shippingAddress').setErrors({ invalid: true });
  }

  clickedNextButton(event: any): void {
    const completedStep = this.receiptForm.get('completedStep');
    this.hideStepperButtons = false;

    if (event.currentIndex === 1) {
      completedStep.setValue(ReceiptSteps.Email);
      if (!this.clickedNextAtEmail) {
        /*
        wait in email step until document_id is received.
        check function saveReceipt, step will be
        automatically moved when id is received
        */
        this.receiptStepper.selectedIndex = 0;
        this.clickedNextAtEmail = true;
      }
    }

    if (event.currentIndex === 2) {
      completedStep.setValue(ReceiptSteps.SelectAnimal);
    }

    if (event.currentIndex === 3) {
      completedStep.setValue(ReceiptSteps.AnimalSize);
    }

    if (event.currentIndex === 4) {
      completedStep.setValue(ReceiptSteps.PurchasedProduct);
      this.getReceiptOrderNumber();
    }

    if (event.currentIndex === 5) {
      completedStep.setValue(ReceiptSteps.UploadReceipt);
    }

    if (event.currentIndex === 6) {
      completedStep.setValue(ReceiptSteps.SelectFlavour);
      this.showShippingAddress = true;
    }

    if (event.currentIndex === 7) {
      completedStep.setValue(ReceiptSteps.ShippingOne);
    }

    if (event.currentIndex === 8) {
      completedStep.setValue(ReceiptSteps.ShippingTwo);
      this.hideStepperButtons = true;
      this.checkIfReceiptExists('address');
      this.isReceiptDuplicate = true;
      this.showLoading = true;
    }

    completedStep.updateValueAndValidity();

    this.saveReceipt();
  }

  moveToStep(stepNumber: number): void {
    this.hideStepperButtons = false;
    this.receiptStepper.selectedIndex = stepNumber;
  }

  confirmAndPlaceOrder(): void {
    this.receiptForm.get('completedStep').setValue(ReceiptSteps.OrderPlaced);
    this.receiptForm.get('status').setValue(ReceiptStatus.Processing);
    this.clickedConfirmOrder = true;
    this.saveReceipt();

    setTimeout(() => {
      this.finalCheckout = true;
    }, 2000);
  }

  saveReceipt(retries = 0): void {
    const receipt = this.receiptForm.value;
    const {
      addressType,
      businessName,
      firstName,
      lastName,
      phoneCode,
      phoneNumber,
    } = receipt;

    const shippingAddress = {
      ...receipt.shippingAddress,
      addressType,
      businessName,
      firstName,
      lastName,
      phoneNumber: `${phoneCode} ${phoneNumber}`,
      email: this.receiptForm.get('email').value,
    };

    const selectedProducts = receipt.selectedProducts;

    receipt.shippingAddress = shippingAddress;
    receipt.selectedProducts = selectedProducts?.description ?? null;
    if (receipt.selectedFlavour) {
      receipt.selectedFlavour.quantity = selectedProducts?.quantity;
    }

    // Set authentication email,
    // this is used to fetch receipts when user logged in
    receipt.authEmail = receipt.email;

    // delete redundant shipping data
    delete receipt.addressType;
    delete receipt.businessName;
    delete receipt.firstName;
    delete receipt.lastName;
    delete receipt.phoneCode;
    delete receipt.phoneNumber;

    if (!receipt.id) {
      delete receipt.id;
    }

    if (!receipt.language) {
      receipt.language = this.currentLocale;
    }

    if (this.isReceiptDuplicate) {
      receipt.isDuplicateReceipt = this.isReceiptDuplicateType;
    }

    this.receiptService
      .createReceipt(receipt)
      .then((response) => {
        /*
          creates new document when `receipt.id` is not
          present in the request and `id` is received
          only for new documents.
          */
        if (response?.id) {
          this.documentId = response.id;
          this.receiptForm.get('id').setValue(response.id);
          /*
            move to animal selection when `id` is received,
            only if user is in email step (first step)
            */
          if (this.receiptStepper.selectedIndex === 0) {
            this.moveToStep(1);
          }
        }
      })
      .catch(() => {
        // retry saving receipt
        if (retries <= 5) {
          this.saveReceipt(retries + 1);
        }
      });
  }

  getReceiptOrderNumber(): void {
    const email = this.receiptForm.get('email').value;

    this.receiptService.getReceipts(email).then((receipts) => {
      const currentReceipt = receipts.find(
        (receipt: IReceipt) => receipt.id === this.documentId,
      );
      this.orderNumber = currentReceipt ? currentReceipt.orderNumber : null;
    });
  }
}
