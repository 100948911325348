import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer
} from '@angular/common';
import { LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { defaultLanguage } from 'constants/languages.constant';
import { localeFromUrl } from 'helpers/locale-from-url.helper';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (platformId: object, request?: Request) => {
        if (isPlatformServer(platformId) && request) {
          return localeFromUrl(request.url);
        }

        if (isPlatformBrowser(platformId)) {
          return localeFromUrl(location.pathname);
        }

        return defaultLanguage.locale;
      },
      deps: [PLATFORM_ID],
    },
  ],
})
export class LocaleModule {}
