export const environment = {
  production: true,
  emulator: null,
  firebaseConfig: {
    apiKey: 'AIzaSyAKXoqpQkFU7f5dsDajIc6pgCgg4a5LsTU',
    authDomain: 'agora-production-db0b0.firebaseapp.com',
    databaseURL: 'https://agora-production-db0b0.firebaseio.com',
    projectId: 'agora-production-db0b0',
    storageBucket: 'dtc-campaign',
    messagingSenderId: '965899846300',
    appId: '1:965899846300:web:26cb47a2a1222f6b916359',
    measurementId: 'G-48WWYMNR02',
    region: 'europe-west3',
  },
  gMapsApiKey: 'AIzaSyBRKR2jWW1xS2bhc1X8nJg7J-d5yx5SmP8',
  googleClientId:
    '965899846300-q70n8qbuakh4v4k2sit5gb8ujfhfk778.apps.googleusercontent.com',
};
