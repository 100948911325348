import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FirebaseModule } from 'src/app/modules/firebase.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { ShippingAddressModule } from '../shipping-address/shipping-address.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ReceiptStepperComponent } from './receipt-stepper/receipt-stepper.component';
import { ReceiptUploadComponent } from './receipt-upload.component';

@NgModule({
  declarations: [
    ReceiptUploadComponent,
    FileUploadComponent,
    ReceiptStepperComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    FirebaseModule,
    CdkStepperModule,
    ReactiveFormsModule,
    FormsModule,
    ShippingAddressModule,
  ],
  exports: [
    ReceiptUploadComponent,
    FileUploadComponent,
    ReceiptStepperComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReceiptUploadModule {
  constructor(injector: Injector) {
    const el = createCustomElement(ReceiptUploadComponent, {
      injector,
    });
    customElements.define('almo-receipt-upload', el);
  }
}
