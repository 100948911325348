import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';

@Component({
  selector: 'app-receipt-stepper',
  templateUrl: './receipt-stepper.component.html',
  styleUrls: ['./receipt-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: ReceiptStepperComponent }],
})
export class ReceiptStepperComponent extends CdkStepper implements OnInit {
  steps: QueryList<CdkStep>;
  @Input() stepIndex: number;
  @Input() hideButtons = false;
  @Input() showLoadingNextButton = false;
  @Output() nextButton = new EventEmitter();
  @Output() prevButton = new EventEmitter();

  ngOnInit(): void {
    if (this.stepIndex) {
      this.selectedIndex = this.stepIndex;
    }
  }

  get progressBarWidth(): number {
    const currentStep = this.selectedIndex + 1;
    const totalSteps = this.steps.length;

    return (currentStep / totalSteps) * 100;
  }

  clickedPrev(): void {
    this.prevButton.emit({
      currentIndex: this.selectedIndex,
    });
  }

  clickedNext(): void {
    this.nextButton.emit({
      currentIndex: this.selectedIndex,
    });
  }
}
