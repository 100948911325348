import { IBrowserDetails } from 'helpers/browser-version.helper';
import { ICampaign } from './campaign.model';
import { IShippingAddress } from './shipping-address.model';

export enum ReceiptStep {
  Upload = 'Receipt Upload',
  ReceiptProductSelection = 'Receipt Product Selection',
  CampaignProductSelection = 'Campaign Product Selection',
  SelectedFlavour = 'Selected Flavour',
  ShippingAddress = 'Shipping Address',
  OrderPlaced = 'Order Placed',
}

export enum ReceiptStatus {
  Processing = 'Processing',
  Approved = 'Approved',
  Booked = 'Booked',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
}

export interface IFile {
  fileUrl: string;
  fileName: string;
  fileType: string;
}

export interface IReceipt {
  id?: string;
  receiptName: string;
  receiptType: string;
  receiptUrl: string;
  ocrAvailable: boolean;
  createdDate: Date | string | any;
  createdTimestamp: Date | string | any;
  campaign: ICampaign;
  email: string;
  authEmail: string;
  language: string;
  shippingAddress: IShippingAddress;
  animalSize: string;
  selectedFlavour: string | any;
  loveFood?: boolean;
  orderNumber?: number;
  receiptProducts?: [];
  selectedProducts?: [];
  status?: ReceiptStatus;
  statusMessage?: string;
  lastSentEmail?: string;
  browserDetails?: IBrowserDetails;
  completedStep?: string;
  isDuplicateReceipt?: any;
  deliveryTracking?: string | any;
}
