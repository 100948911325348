import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
  availableLanguages,
  defaultLanguage,
  Language,
} from 'constants/languages.constant';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentLang$ = new BehaviorSubject<Language>(defaultLanguage);
  constructor(
    private readonly router: Router,
    private readonly translocoService: TranslocoService
  ) {}

  async changeLanguage(langCode: string): Promise<void> {
    const tree = this.router.parseUrl(this.router.url);
    const primaryOutlet = tree.root.children[PRIMARY_OUTLET];
    primaryOutlet.segments[0] = new UrlSegment(langCode, {});
    tree.root.children[PRIMARY_OUTLET] = primaryOutlet;

    const url = this.router.serializeUrl(tree);
    await this.router.navigateByUrl(url);
    this.setActiveLang(langCode);
  }

  setActiveLang(langCode: string): Language {
    if (availableLanguages.map((lang) => lang.locale).includes(langCode)) {
      const language: Language = availableLanguages.filter(
        (lang) => lang.locale === langCode
      )[0];
      this.currentLang$.next(language);
      this.translocoService.setActiveLang(language.locale);
      return language;
    }
    return null;
  }

  getActiveLang(): Observable<Language> {
    return this.currentLang$.asObservable();
  }
}
