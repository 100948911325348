import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from 'src/app/modules/material.module';
import { ShippingAddressComponent } from './shipping-address.component';

@NgModule({
  declarations: [ShippingAddressComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [ShippingAddressComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShippingAddressModule {}
