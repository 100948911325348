import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { getMimeTypeFromExtension } from 'constants/mime-types.constant';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'app-receipt-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() storagePath: string;
  @Input() fileName: string = null;
  @Output() fileUploaded = new EventEmitter();
  @Output() changeFile = new EventEmitter();
  @Output() uploadStarted = new EventEmitter();

  fileControl = new FormControl(null);
  fileType: string;
  isFileLoaded = false;
  removeWatchFileSubscription$ = new Subject();

  private onTouched: () => void;
  private subscription = new Subscription();

  constructor(
    private ngControl: NgControl,
    private fileUploadService: FileUploadService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.fileControl.setValidators(this.ngControl.control.validator);
    this.fileControl.updateValueAndValidity({ emitEvent: false });
    this.subscription.add(
      this.fileControl.valueChanges.subscribe(() => {
        this.cdRef.detectChanges();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isLoading(): boolean {
    return !!this.fileControl.value?.isLoading;
  }

  get isFinish(): boolean {
    return (
      !this.fileControl.value?.isLoading &&
      !this.fileControl.value?.percentage &&
      this.fileControl?.value
    );
  }

  get percentage(): number {
    return this.fileControl.value?.percentage > 10
      ? this.fileControl.value?.percentage
      : 10;
  }

  writeValue(value?: any): void {
    if (value) {
      this.fileType = getMimeTypeFromExtension(
        value.split('?')[0].split('.').pop(),
      );

      if (this.fileType === undefined) {
        // set default type to image
        this.fileType = getMimeTypeFromExtension('jpg');
      }

      this.fileControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.subscription.add(this.fileControl.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fileControl.disable() : this.fileControl.enable();
  }

  validate(): ValidationErrors | null {
    return this.fileControl.valid ? null : { invalid: true };
  }

  onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.fileName = target.files[0].name;
    this.fileType = target.files[0].type;
    this.uploadStarted.emit(true);
    this.uploadFile(target.files[0]);
  }

  changeFileClick(): void {
    this.fileControl.patchValue(null);
    this.isFileLoaded = false;
    this.changeFile.emit(true);
  }

  private emitFileData(): void {
    this.subscription.add(
      this.fileControl.valueChanges
        .pipe(takeUntil(this.removeWatchFileSubscription$))
        .subscribe((val) => {
          if (typeof val === 'string') {
            if (
              this.fileType === 'image/tiff' ||
              this.fileType === 'image/tif'
            ) {
              this.isFileLoaded = true;
            }
            this.fileUploaded.emit({
              fileType: this.fileType,
              fileName: this.fileName,
              fileUrl: val,
            });
            this.removeWatchFileSubscription$.next();
          }
        }),
    );
  }

  private async uploadFile(file: File): Promise<void> {
    const uploadTask = await this.fileUploadService.fileUpload(
      file,
      this.storagePath,
    );
    this.fileControl.setValue({
      isLoading: false,
      percentage: 0,
    });

    this.subscription.add(
      this.fileUploadService
        .watchFileUpload(uploadTask, this.fileControl)
        .pipe(
          takeUntil(this.removeWatchFileSubscription$),
          finalize(() => this.emitFileData()),
        )
        .subscribe(),
    );
  }
}
