// Capture language locale (`en-US`), with optional slash
export const localePathRegex = /^\/?([a-z]{2}\-[A-Z]{2})(?:\/.*)?$/;

/**
 * Email Regex Pattern
 *
 * Got from `emailregex.com` on Javascript section
 */
export const emailRegexPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Validate date format DD-MMM-YYYY
export const dateStringRegexPattern =
  /^(([0-9])|([0-2][0-9])|([3][0-1]))\-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\-\d{4}$/;
