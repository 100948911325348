<section class="file-upload receipt-upload"
         *transloco="let t; read: 'formData.upload'">
  <div *ngIf="!isFinish"
       class="file-upload__wrapper">

    <!-- Receipt Upload Button -->
    <div class='fadeIn pt-20'
         *ngIf="!isLoading">
      <label class="input-file almo-btn almo-btn__primary">
        <input type="file"
               (change)="onFileChange($event)"
               accept=".png, .jpg, .pdf" />
        <span>{{ t('uploadImage') }}</span>
      </label>
      <div class="pt-10 almo-fs-small opacity-7">
        {{ t('receiptUploadFileTypes') }}
      </div>
    </div>

    <!-- Uploading Percentage Progress Bar -->
    <div class="fadeIn"
         *ngIf="isLoading">
      <div>
        {{ t('uploading') }}
        <span class="text-secondary"> {{ fileName }} </span>
      </div>
      <div class="progress mt-20 mb-10">
        <div class="progress-bar bg-secondary"
             [style.width.%]="percentage"></div>
      </div>
      <div class="text-left">
        <span *ngIf="percentage !== 100">
          <span class="text-secondary">
            {{ percentage }}%
          </span> {{ t('of') }} 100%
        </span>

        <span class="text-secondary"
              *ngIf="percentage === 100">
          {{ t('completed') }}
        </span>
      </div>
    </div>
  </div>

  <!-- Receipt after finish layout -> File name and change icon -->
  <div *ngIf="isFinish"
       class="fadeIn file-upload__wrapper pt-10">
    <div class="row">
      <div class="col-md-7">
        <div class="bg-white border box-shadow px-15 rounded py-15">
          <a href="{{ fileControl?.value }}"
             class="d-flex w-100 justify-content-center align-items-center"
             target="_blank"
             rel="noopener">
            {{ fileName ? fileName.split('.')[0] : t('fileUploaded') }} <mat-icon class="ml-10">launch</mat-icon>
          </a>
        </div>
      </div>
      <div class="col-md-5 mt-20 mt-md-0 align-self-center">
        <button mat-button
                color="primary"
                (click)="changeFileClick()">
          <mat-icon>shuffle</mat-icon>
          {{ t('changeFile') }}
        </button>
      </div>
    </div>
  </div>
</section>
