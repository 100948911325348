import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { first } from 'rxjs/operators';
import { LanguageService } from 'services/language.service';

function initializer(
  transloco: TranslocoService,
  languageService: LanguageService,
  locale: string
): () => Promise<any> {
  return (): Promise<any> => {
    // transloco.setActiveLang(locale);
    languageService.setActiveLang(locale);
    return transloco.load(locale).pipe(first()).toPromise();
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializer,
      deps: [TranslocoService, LanguageService, LOCALE_ID],
    },
  ],
})
export class AppInitializerModule {}
