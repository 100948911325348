import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslocoService } from '@ngneat/transloco';
import firebase from 'firebase/app';
import { IDeleteUser, IUser, User } from 'models/user.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private collectionName = 'pet-parents';
  private deleteUserCollectionName = 'delete-users';
  user$ = new BehaviorSubject<IUser>(null);

  constructor(
    private readonly fireAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private readonly languageService: LanguageService,
    private readonly translocoService: TranslocoService,
  ) {}

  watchActiveUser(): Observable<User> {
    return this.fireAuth.user.pipe(
      switchMap((authUser) =>
        authUser && !authUser.isAnonymous ? this.getUser(authUser) : of(null),
      ),
      tap((user) => this.user$.next(user)),
    );
  }

  getUser(auth: firebase.User): Observable<User> {
    const uid = auth.uid ?? auth.providerData[0].uid;
    const docRef = this.afs.collection<IUser>(this.collectionName).doc(uid);

    return docRef.valueChanges().pipe(
      tap((user: IUser) => (!user ? this.createUser(auth) : null)),
      map((user: IUser) => (user ? new User(user) : null)),
    );
  }

  updateUser(user: User): Promise<void> {
    const docRef = this.afs
      .collection<IUser>(this.collectionName)
      .doc(user.uid);
    return docRef.update(user.toJson());
  }

  /*
  If user is registered from any identity provider then
  displayName and photoURL exists inside providerData array
  */
  createUser(auth: firebase.User): Promise<void> {
    const uid = auth.uid;
    const email = auth.email;
    const createdDate = auth.metadata.creationTime ?? '';
    const firstName = auth.displayName ?? auth.providerData[0].displayName;
    const photoURL = auth.photoURL ?? auth.providerData[0].photoURL;
    const phoneNumber = auth.phoneNumber ?? '';
    const language = this.languageService.currentLang$.getValue().locale;
    const country = this.languageService.currentLang$.getValue().countryCode;
    const findPasswordProvider = auth.providerData.find(
      (provider: any) => provider.providerId === 'password',
    );
    const hasPasswordProvider = findPasswordProvider ? true : false;

    const user = new User({
      uid,
      email,
      createdDate,
      firstName: firstName ?? '',
      photoURL: photoURL ?? '',
      phoneNumber: phoneNumber ?? '',
      language,
      country,
      hasPasswordProvider,
    }).toJson();
    const docRef = this.afs.collection<IUser>(this.collectionName).doc(uid);

    return docRef.set(user);
  }

  deleteUser(user: IDeleteUser): Promise<void> {
    console.log(user);
    const docRef = this.afs
      .collection<IDeleteUser>(this.deleteUserCollectionName)
      .doc(user.uid);

    return docRef.set(user);
  }

  getPrivacyPolicyUrl(): string {
    const currentLang = this.languageService.currentLang$.getValue();
    const lang = currentLang.langCode.toLowerCase();
    const country = currentLang.countryCode.toLowerCase();
    const websiteCountryCode =
      lang === country ? lang : `${lang}_${country.toUpperCase()}`;
    const baseUrl = `https://www.almonature.com/${websiteCountryCode}/`;
    return `${baseUrl}privacy-policy/`;
  }

  getPrivacyStatement(): HTMLElement {
    const currentLang = this.languageService.currentLang$.getValue();
    const lang = currentLang.langCode.toLowerCase();
    const country = currentLang.countryCode.toLowerCase();
    const websiteCountryCode =
      lang === country ? lang : `${lang}_${country.toUpperCase()}`;
    const baseUrl = `https://www.almonature.com/${websiteCountryCode}/`;
    const privacyLink = `${baseUrl}privacy-policy/`;
    const cookieLink = `${baseUrl}cookie-policy/`;

    const privacyTranslation = this.translocoService.translate(
      'privacy.privacyPolicy',
    );
    const cookieTranslation = this.translocoService.translate(
      'privacy.cookiePolicy',
    );

    const privacyHTML = `<a target="_blank" href="${privacyLink}">${privacyTranslation}</a>`;
    const cookieHTML = `<a target="_blank" href="${cookieLink}">${cookieTranslation}</a>`;

    return this.translocoService.translate('privacy.statement', {
      cookiePolicy: cookieHTML,
      privacyPolicy: privacyHTML,
    });
  }
}
