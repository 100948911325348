import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReceiptUploadModule } from 'campaigns/components/receipt-upload/receipt-upload.module';
import { AppInitializerModule } from 'modules/app-initializer.module';
import { FirebaseModule } from 'modules/firebase.module';
import { IconModule } from 'modules/icon.module';
import { LocaleModule } from 'modules/locale.module';
import { TranslocoRootModule } from 'modules/transloco-root.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    LocaleModule,
    AppInitializerModule,
    FirebaseModule,
    BrowserAnimationsModule,
    IconModule,
    ReceiptUploadModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
