export interface IBrowserDetails {
  browserName: string;
  version: string;
  userAgent: string;
  vendor: string;
  platform: string;
}

export function getBrowserDetails(): IBrowserDetails {
  return {
    browserName: navigator.appName,
    version: navigator.appVersion,
    userAgent: navigator.userAgent,
    vendor: navigator.vendor,
    platform: navigator.platform,
  } as IBrowserDetails;
}
