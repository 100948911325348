import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  countryPostalRegEx,
  ICountryPostalRegEx,
} from 'constants/countries-postal.constant';
import { italyProvinces } from 'constants/italy-provinces.constant';
import { IShippingAddress } from 'models/shipping-address.model';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GoogleMapsService } from 'services/google-maps.service';

declare let google: any;

@Component({
  selector: 'app-almo-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ShippingAddressComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() address: IShippingAddress = null;
  @Input() countryCode = null;
  @Input() saveButtonText: string = null;
  @Input() hideSaveButton = false;
  @Input() countryReadOnly = false;
  @Output() formSubmitted = new EventEmitter();
  @Output() formFieldUpdated = new EventEmitter();
  @Output() validAddress = new EventEmitter();
  @Output() invalidAddress = new EventEmitter();

  @ViewChild('streetAddress') searchElementRef: ElementRef;
  @ViewChild('doorNumber') doorNumberRef: ElementRef;
  @ViewChild('city') cityElementRef: ElementRef;
  @ViewChild('province') provinceRef: ElementRef;
  @ViewChild('provinceItaly') provinceItalyRef: ElementRef;
  @ViewChild('postalCode') postalCodeRef: ElementRef;
  @ViewChild('saveShippingAddressBtn') saveShippingAddressBtn: ElementRef;
  @ViewChild('errorMessageElement') errorMessageElementRef: ElementRef;
  @ViewChild('autoCompletePlaceholder') autoCompletePlaceholder: ElementRef;
  shippingAddress: FormGroup;
  subscription = new Subscription();
  countries = countryPostalRegEx;
  countryPhoneCode = null;
  italyProvinces = italyProvinces;
  autocomplete = null;
  geocoder = null;

  // Italy Lat and Lng
  lat = 41.8719;
  lng = 12.5674;
  zoomLevel = 15;

  constructor(
    private formBuilder: FormBuilder,
    private gMapsService: GoogleMapsService,
  ) {}

  ngOnInit(): void {
    if (!this.countryCode) {
      this.countryCode = 'IT';
    }

    this.countryPhoneCode = this.countries.find(
      (country) => country.code === this.countryCode,
    )?.countryPhoneCode;

    this.initFormBuilder();

    this.shippingAddress.valueChanges.subscribe(() => {
      this.formFieldUpdated.emit();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initAutoComplete();
      this.changeStreetAddressValidators();
    }, 3000);
    this.subscription.add(
      this.shippingAddress.valueChanges
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.saveShippingAddressBtn.nativeElement.disabled = false;
          this.errorMessageElementRef.nativeElement.style.display = 'none';
          // remove all UI errors
          this.shippingAddress.setErrors(null);
          if (this.shippingAddress.valid) {
            this.emitFormValues();
          } else {
            this.invalidAddress.emit(true);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isWorkAddress(): boolean {
    return this.shippingAddress.get('addressType').value === 'work';
  }

  get shippingCountryCode(): string {
    return this.shippingAddress.get('country').value;
  }

  private initFormBuilder(): void {
    this.shippingAddress = this.formBuilder.group({
      country: [
        { value: this.countryCode, disabled: this.countryReadOnly },
        Validators.required,
      ],
      streetAddress: [this.address?.streetAddress, Validators.required],
      doorNumber: [this.address?.doorNumber, Validators.required],
      city: [this.address?.city, Validators.required],
      province: [this.address?.province],
      postalCode: [
        this.address?.postalCode,
        [Validators.required, Validators.maxLength(20)],
      ],
      carrierNote: [
        this.address?.carrierNote ?? null,
        Validators.maxLength(249),
      ],
      coordinates: this.formBuilder.group({
        lat: [this.address?.coordinates?.lat],
        lng: [this.address?.coordinates?.lng],
      }),
    });
    this.changeProvinceValidators();
    this.updatePostalRegex();
  }

  changeProvinceValidators(): void {
    if (this.countryCode === 'IT') {
      this.shippingAddress.get('province').setValidators(Validators.required);
    } else {
      this.shippingAddress.get('province').setValue(null);
      this.shippingAddress.get('province').clearValidators();
    }
    this.shippingAddress.get('province').updateValueAndValidity();
  }

  private initAutoComplete(): void {
    const countryCode = this.countryCode;
    if (this.searchElementRef.nativeElement) {
      if (google && google.maps) {
        // initialize autocomplete
        this.autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {},
        );

        this.autocomplete.setComponentRestrictions({
          country: [countryCode],
        });

        this.autocomplete.setFields([
          'address_components',
          'formatted_address',
          'geometry',
        ]);

        google.maps.event.addListener(
          this.autocomplete,
          'place_changed',
          this.onAutoCompletePlaceChange.bind(this),
        );

        if (this.address?.country) {
          this.autocomplete.setComponentRestrictions({
            country: [this.address.country],
          });
        }

        if (this.autoCompletePlaceholder) {
          setTimeout(() => {
            const pacContainer = document.querySelector('.pac-container');
            this.autoCompletePlaceholder.nativeElement.prepend(pacContainer);
          }, 500);
        }
      }
    }
  }

  userChangedCountry(countryCode: any): void {
    this.countryCode = countryCode;
    this.autocomplete.setComponentRestrictions({
      country: [countryCode],
    });
    this.changeProvinceValidators();
    this.changeStreetAddressValidators();
  }

  changeStreetAddressValidators(): void {
    if (this.countryCode === 'IT') {
      // set reactive form validators
      this.shippingAddress.get('doorNumber').clearValidators();
      this.shippingAddress
        .get('streetAddress')
        .setValidators([Validators.required, Validators.maxLength(35)]);

      // set html attribute for streetAddress
      this.searchElementRef.nativeElement.setAttribute('maxlength', '35');
    } else {
      // set reactive form validators
      this.shippingAddress.get('streetAddress').clearValidators();
      this.shippingAddress
        .get('doorNumber')
        .setValidators([Validators.required]);
      this.shippingAddress
        .get('streetAddress')
        .setValidators([Validators.required]);

      // set html attribute for streetAddress
      this.searchElementRef.nativeElement.removeAttribute('maxlength');
    }
    this.shippingAddress.get('streetAddress').updateValueAndValidity();
    this.shippingAddress.get('doorNumber').updateValueAndValidity();
  }

  getStreetAddressForItaly(place: any, address: IShippingAddress): string {
    const doorNumber = address.doorNumber ? address.doorNumber : '';
    let streetAddress: string;

    if (address.streetAddress) {
      streetAddress = address.streetAddress;
    } else {
      streetAddress = place?.formatted_address.trim();
    }

    const formattedAddress = `${doorNumber} ${streetAddress}`;
    return formattedAddress.substring(0, 35).trim();
  }

  private onAutoCompletePlaceChange(): void {
    const place = this.autocomplete.getPlace();
    const address = this.gMapsService.onAddressChange(place);

    if (address.city) {
      this.shippingAddress.get('city').setValue(address.city.trim());
    }

    if (this.countryCode === 'IT') {
      const streetAddress = this.getStreetAddressForItaly(place, address);
      this.shippingAddress.get('streetAddress').setValue(streetAddress);
    } else {
      // countries other than italy has individual doorNumber and streetAddress
      if (address.doorNumber) {
        const doorNumber = address.doorNumber.trim();
        this.shippingAddress.get('doorNumber').setValue(doorNumber);
      }

      if (address.streetAddress) {
        const streetAddress = address.streetAddress.trim();
        this.shippingAddress.get('streetAddress').setValue(streetAddress);
      } else {
        const streetAddress = place?.formatted_address.trim();
        this.shippingAddress.get('streetAddress').setValue(streetAddress);
      }
    }

    if (address.province && this.countryCode === 'IT') {
      this.shippingAddress.get('province').setValue(address.province.trim());
    } else {
      this.shippingAddress.get('province').setValue(null);
    }

    if (address.postalCode) {
      this.shippingAddress
        .get('postalCode')
        .setValue(address.postalCode.trim());
      this.shippingAddress.get('postalCode').updateValueAndValidity();
    }

    if (address.coordinates.lat) {
      this.shippingAddress
        .get('coordinates')
        .get('lat')
        .setValue(address.coordinates.lat);
    }

    if (address.coordinates.lng) {
      this.shippingAddress
        .get('coordinates')
        .get('lng')
        .setValue(address.coordinates.lng);
    }

    this.shippingAddress.updateValueAndValidity();
    this.postalCodeRef.nativeElement.focus();
  }

  updatePostalRegex(): void {
    const addPostalRegex = (countryCode: string) => {
      const matchedCountry: ICountryPostalRegEx = this.countries.find(
        (country) => country.code === countryCode,
      );

      if (matchedCountry && matchedCountry?.postal) {
        const postalCode = this.shippingAddress.get('postalCode');
        // RegEx needs to be passed as string to Validators.pattern
        const regex = new RegExp(matchedCountry.postal).source;
        postalCode.setValidators([
          Validators.required,
          Validators.pattern(regex),
        ]);
        postalCode.updateValueAndValidity();
        postalCode.markAsDirty();
      }
    };

    if (this.countryCode) {
      addPostalRegex(this.countryCode);
    }

    this.subscription.add(
      this.shippingAddress
        .get('country')
        .valueChanges.subscribe((countryCode) => {
          addPostalRegex(countryCode);
          this.userChangedCountry(countryCode);
        }),
    );
  }

  checkFormFieldValidation(field: string): boolean {
    return (
      this.shippingAddress.get(field).touched &&
      this.shippingAddress.get(field).invalid
    );
  }

  emitFormValues(): void {
    if (this.shippingAddress.valid) {
      const address = this.getFinalUserAddress();
      const finalAddress = {
        ...address,
        streetAddress: address.streetAddress.toUpperCase(),
        city: address.city.toUpperCase(),
        postalCode: address.postalCode.toUpperCase(),
      };
      if (this.countryCode !== 'IT') {
        finalAddress.doorNumber = address.doorNumber.toUpperCase();
      } else {
        finalAddress.doorNumber = '';
      }
      this.errorMessageElementRef.nativeElement.style.display = 'none';
      this.formSubmitted.emit(finalAddress);
      this.validAddress.emit(finalAddress);
      this.saveShippingAddressBtn.nativeElement.disabled = true;
      setTimeout(
        () => (this.saveShippingAddressBtn.nativeElement.disabled = false),
        1000,
      );
    }
  }

  getFinalUserAddress(): any {
    const address = this.shippingAddress.getRawValue();
    const doorNumber = address.doorNumber;
    const streetAddress = address.streetAddress;

    // check if doorNumber exists in streetAddress
    if (streetAddress.indexOf(doorNumber) > -1) {
      this.shippingAddress
        .get('streetAddress')
        .setValue(streetAddress.replace(doorNumber, ''));
    }

    return this.shippingAddress.getRawValue();
  }
}
