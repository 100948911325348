import {
  availableLanguages,
  defaultLanguage,
  Language,
} from 'constants/languages.constant';

export function localeFromUrl(url: string): string {
  const parsedUrl = url.split('/');
  const currentLocaleFromUrl = parsedUrl[1];
  const language: Language = availableLanguages.find(
    (lang) => lang.locale === currentLocaleFromUrl
  );

  if (language) {
    return language.locale;
  }
  return defaultLanguage.locale;
}
