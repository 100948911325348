import { UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';
import { availableLanguages } from 'constants/languages.constant';

export const almoLocaleUrlMatcher: UrlMatcher = (segments: UrlSegment[]) => {
  const checkLanguage = availableLanguages
    .map((lang) => lang.locale)
    .includes(segments[0].path);

  if (segments.length && checkLanguage) {
    return {
      consumed: segments.slice(0, 1),
      posParams: {
        language: new UrlSegment(segments[0].path, {}),
      },
    } as UrlMatchResult;
  }
  return null;
};
